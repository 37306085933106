import { Controller } from "@hotwired/stimulus"

const CONTENT_BLOCK_TYPES = [
  "ContentBlocks::Code",
  "ContentBlocks::File",
  "ContentBlocks::Image",
  "ContentBlocks::Link",
  "ContentBlocks::Narrative",
  "ContentBlocks::StickyNote",
  "ContentBlocks::Table",
  "ContentBlocks::Text"
];

// Connects to data-controller="cue-form"
export default class extends Controller {

  static get targets() {
    return [
      "contentBlockForm",
      "contentBlockType",
      "newContentBlockButton",
      "scenarioForm",
      "textForm",
      "viewAllButton"
    ];
  }

  updateForm(event) {
    event.preventDefault();
    this.hideForms();
    switch(event.target.value) {
      case "Scenario":
        this.showForm(this.scenarioFormTarget);
        break;
      case "TextBlock":
        this.showForm(this.textFormTarget);
        break;
      default:
        this.showContentBlockForm(event.target.value);
    }
  }

  showForm(formTarget) {
    formTarget.classList.remove("is-hidden");
  }

  showContentBlockForm(contentBlockType) {
    if (CONTENT_BLOCK_TYPES.includes(contentBlockType)) {
      this.updateViewAllButtonURL(contentBlockType);
      this.updateNewContentBlockButtonURL(contentBlockType);
      this.showForm(this.contentBlockFormTarget);
      this.contentBlockTypeTarget.value = contentBlockType;
    }
  }

  hideForms() {
    const formTargets = [
      this.textFormTarget,
      this.scenarioFormTarget,
      this.contentBlockFormTarget
    ];

    formTargets.forEach(formTarget => formTarget.classList.add("is-hidden"));
  }

  updateViewAllButtonURL(contentBlockType) {
    const url = `/content_management/content_blocks/${this.contentBlockPath(contentBlockType)}s/`;
    this.viewAllButtonTarget.href = url;
  }

  updateNewContentBlockButtonURL(contentBlockType) {
    const currentUrl = this.newContentBlockButtonTarget.dataset.route;
    const url = currentUrl.replace(/(\?.*)|$/, `?content_block_type=${contentBlockType}`);

    this.newContentBlockButtonTarget.dataset.route = url;
  }

  contentBlockPath(contentBlock) {
    return contentBlock.split("::").pop().replace(/(.)([A-Z])/g, function(match, p1, p2) {
      return p1 + "_" + p2;
    }).toLowerCase();
 }
}
